exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-exhibitions-js": () => import("./../../../src/pages/exhibitions.js" /* webpackChunkName: "component---src-pages-exhibitions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modal-js": () => import("./../../../src/pages/modal.js" /* webpackChunkName: "component---src-pages-modal-js" */),
  "component---src-pages-stained-glass-js": () => import("./../../../src/pages/stained-glass.js" /* webpackChunkName: "component---src-pages-stained-glass-js" */),
  "component---src-pages-tabbed-exhibitions-js": () => import("./../../../src/pages/tabbedExhibitions.js" /* webpackChunkName: "component---src-pages-tabbed-exhibitions-js" */),
  "component---src-pages-view-item-js": () => import("./../../../src/pages/viewItem.js" /* webpackChunkName: "component---src-pages-view-item-js" */),
  "component---src-templates-lightbox-js": () => import("./../../../src/templates/lightbox.js" /* webpackChunkName: "component---src-templates-lightbox-js" */),
  "component---src-templates-paginated-page-template-js": () => import("./../../../src/templates/paginatedPageTemplate.js" /* webpackChunkName: "component---src-templates-paginated-page-template-js" */)
}

